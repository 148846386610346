import axios from "axios";
import { CandidateActions } from "./enums";
import { useAsyncState } from "@vueuse/core";
import { useApiBaseUrl } from '~/composables/useApiBaseUrl'

export const uploadResume = async (file: any) => {
  return useAsyncState(axios.post(`${useApiBaseUrl()}/${CandidateActions.RESUME_PATH}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((t) => t.data),
    undefined);
};

export const getAllCandidates = async () => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${CandidateActions.CANDIDATES_PATH}`).then(t => t.data), undefined,
  )
};

export const updateCandidate = async (payload: any, hash_id: string) => {
  return useAsyncState(axios.put(`${useApiBaseUrl()}/${CandidateActions.CANDIDATE_PATH}/${hash_id}`, JSON.stringify(payload), {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((t) => t.data),
    undefined);
};

export const deleteCandidate = async (hash_id: any) => {
  return useAsyncState(
    axios.delete(`${useApiBaseUrl()}/${CandidateActions.CANDIDATE_PATH}/${hash_id}`).then(t => t.data), undefined,
  )
};
export const getOneCandidate = async (hash_id: any) => {
  return useAsyncState(await axios.get(
    `${useApiBaseUrl()}/${CandidateActions.CANDIDATE_PATH}/${hash_id}`
  ).then(t => t.data), undefined)
};

export const downloadResume = async (hashId: string) => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${CandidateActions.RESUME_PATH}/${hashId}`).then(t => t.data), undefined,
  )
};

export const getAllResumeStatus = async () => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${CandidateActions.RESUMES_PATH}`).then(t => t.data), undefined,
  )
};

export const getAllCallStatus = async () => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${CandidateActions.CALL_PATH}`).then(t => t.data), undefined,
  )
};

export const getAllCallDetails = async (callSid: string) => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${CandidateActions.CALL_PATH}/${callSid}`).then(t => t.data), undefined,
  )
};

export const getUpcomingCallDetails = async (callSid: string) => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${CandidateActions.CALL_PATH}/${CandidateActions.UPCOMING_PATH}/${callSid}`).then(t => t.data), undefined,
  )
};

export const scheduleCall = async (payload: any) => {
  return useAsyncState(axios.post(`${useApiBaseUrl()}/${CandidateActions.CALL_PATH}`, JSON.stringify(payload), {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((t) => t.data),
    undefined);
};

export const cancelScheduledCall = async (call_id: string) => {
  return useAsyncState(
    axios.delete(`${useApiBaseUrl()}/${CandidateActions.CALL_PATH}/upcoming/${call_id}`).then(t => t.data), undefined,
  )
};
export const updateCandidateStatus = async (hashId: string, status: string) => {
  return useAsyncState(
    axios
      .patch(`${useApiBaseUrl()}/${CandidateActions.CANDIDATE_PATH}/${hashId}/${CandidateActions.STATUS_PATH}/${status}`)
      .then((t) => t.data),
    {}
  );
};
