import { CandidatesList } from './../.nuxt/components.d';
import { defineStore } from 'pinia'
import { type Candidate } from '~/types/candidate'
import { getAllCandidates, updateCandidate, updateCandidateStatus, getOneCandidate, deleteCandidate, downloadResume, getAllResumeStatus, getAllCallStatus, getAllCallDetails, scheduleCall, uploadResume, getUpcomingCallDetails, cancelScheduledCall } from '@/api/candidate'
export const useMyCandidatesStore = defineStore({
  id: 'myCandidatesStore',
  state: () => ({
    candidates: [] as Candidate[],
    candidate: {} as Candidate,
  }),
  actions: {
    async findOneCandidate(hash_id: string) {
      const { state, error } = await getOneCandidate(hash_id)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error fetching candidate details" }
      } else {
        this.candidate = state.value?.data
        return { status: 200, data: state.value?.data }
      }
    },
    async getAllCandidates() {
      const { state, error } = await getAllCandidates()
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error fetching candidate details" }
      } else {
        this.candidates = state.value?.data
        return { status: 200, data: state.value?.data }
      }
    },
    async uploadResume(file: FormData) {
      const { state, error } = await uploadResume(file)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error uploading resume" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async updateCandidate(payload: any, hash_id: string) {
      const { state, error } = await updateCandidate(payload, hash_id)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error updating candidate" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async deleteCandidate(hash_id: string) {
      const { state, error } = await deleteCandidate(hash_id)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Something went wrong while deleting the candidate" }
      } else {
        this.candidates = this.candidates.filter((item) => item.hash_id === hash_id)
        return { status: 200, data: state.value?.data }
      }
    },
    async downloadResume(hashId: any) {
      const { state, error } = await downloadResume(hashId)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error downloading resume" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async getAllResumeStatus() {
      const { state, error } = await getAllResumeStatus()
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error fetching resume status" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async getAllCallStatus() {
      const { state, error } = await getAllCallStatus()
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error fetching call status" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async getAllCallDetails(callSid: any) {
      const { state, error } = await getAllCallDetails(callSid)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error fetching call details" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async getUpcomingCallDetails(callSid: any) {
      const { state, error } = await getUpcomingCallDetails(callSid)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error fetching upcoming call details" }
      } else {
        return { status: 200, data: state.value.data }
      }
    },
    async scheduleCall(payload: any) {
      const { state, error } = await scheduleCall(payload)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error scheduling call" }
      } else {
        return { status: 200, data: state.value }
      }
    },
    async cancelScheduledCall(call_id: any) {
      const { state, error } = await cancelScheduledCall(call_id)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error canclening scheduled call" }
      } else {
        return { status: 200, data: state.value }
      }
    },
    async updateCandidateStatus(hashId: string, status: string) {
      const { state, error } = await updateCandidateStatus(hashId, status)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error updating the candidate status" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    }
  }
})
